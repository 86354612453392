import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import Logs from './Logs';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


function App() {
  return (
    <Router>
      <Routes>
	{/* Page d'inscription */}
        <Route path="/customerregistration" element={<Register />} />
        {/* Page de connexion */}
        <Route path="/login" element={<Login />} />
        {/* Page d’affichage des logs, accessible après connexion */}
        <Route path="/logs" element={<Logs />} />
      </Routes>
    </Router>
  );
}

export default App;
