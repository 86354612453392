import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Logs() {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [error, setError] = useState('');
  const [pageSize, setPageSize] = useState('tout');
  const [sortBy, setSortBy] = useState('date');
  const [selectedDay, setSelectedDay] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
      return;
    }

    const fetchLogs = async () => {
      try {
        const res = await fetch('https://pro.assonumeric.fr:5000/api/logs', {
          headers: { Authorization: token },
        });
        if (res.status === 401) {
          navigate('/');
        } else if (res.ok) {
          const data = await res.json();
          const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
          setLogs(sortedData);
          setFilteredLogs(sortedData);
        } else {
          setError('Erreur lors de la récupération des logs');
        }
      } catch (err) {
        setError('Erreur serveur');
      }
    };

    fetchLogs();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleDayFilterChange = (e) => {
    const value = e.target.value;
    setSelectedDay(value);
    if (value === 'all') {
      setFilteredLogs(logs);
    } else {
      const filtered = logs.filter((log) => {
        const logDay = new Date(log.date).toLocaleDateString('fr-FR', { weekday: 'long' });
        return logDay.toLowerCase() === value.toLowerCase();
      });
      setFilteredLogs(filtered);
    }
  };


  const totalPages = pageSize === 'tout' ? 1 : Math.ceil(filteredLogs.length / Number(pageSize));
  const displayedLogs = pageSize === 'tout' ? filteredLogs : filteredLogs.slice((currentPage - 1) * 99999, currentPage * pageSize);

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="text-info">Mes Logs</h2>
        <button className="btn btn-danger" onClick={handleLogout}>Se Déconnecter</button>
      </div>
      <div className="card shadow-lg p-4" style={{ background: '#1a1a2e', color: '#fff' }}>
        {error && <div className="alert alert-danger text-center">{error}</div>}
        <div className="d-flex justify-content-between mb-3">
          <div>
            <label className="me-2 text-light">Afficher :</label>
            <select className="form-select w-auto d-inline" onChange={handlePageSizeChange}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="tout">Tout</option>
            </select>
          </div>
          <div>
            <label className="me-2 text-light">Trier par :</label>
            <select className="form-select w-auto d-inline" onChange={handleSortChange}>
              <option value="date">Date</option>
            </select>
          </div>
          {sortBy === 'date' && (
            <div>
              <label className="me-2 text-light">Filtrer par jour :</label>
              <select className="form-select w-auto d-inline" onChange={handleDayFilterChange}>
                <option value="all">Tous</option>
                <option value="lundi">Lundi</option>
                <option value="mardi">Mardi</option>
                <option value="mercredi">Mercredi</option>
                <option value="jeudi">Jeudi</option>
                <option value="vendredi">Vendredi</option>
                <option value="samedi">Samedi</option>
                <option value="dimanche">Dimanche</option>
              </select>
            </div>
          )}
        </div>
        <div className="table-responsive">
          <table className="table table-dark table-striped table-hover">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Date</th>
              <th scope="col">Tag ID</th>
            </tr>
            </thead>
            <tbody>
            {displayedLogs.map((log, index) => (
              <tr key={log._id}>
                <th scope="row">{index + 1}</th>
                <td>{new Date(log.date).toLocaleString()} ({new Date(log.date).toLocaleDateString('fr-FR', { weekday: 'long' })})</td>
                <td>{log.tag_id}</td>
              </tr>
            ))}
            {filteredLogs.length === 0 && (
              <tr>
                <td colSpan="3" className="text-center text-warning">Aucun log disponible</td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        {pageSize !== 'tout' && totalPages > 1 && (
          <div className="d-flex justify-content-center mt-3">
            <button className="btn btn-light mx-2" disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>Précédent</button>
            <span className="text-light">Page {currentPage} sur {totalPages}</span>
            <button className="btn btn-light mx-2" disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>Suivant</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Logs;
