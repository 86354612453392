import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Register.css';

function Register() {
  const [formData, setFormData] = useState({
    login: '',
    password: '',
    tag_id: '',
    email: '',
    phone: '',
    nom_contact: '',
    n_siret: '',
    contact: '',
    adresse: '',
    code_postal: '',
    ville: ''
  });
  const navigate = useNavigate(); // Hook pour la navigation
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Validation de tous les champs
    for (let key in formData) {
      if (!formData[key].trim()) {
        setError('Tous les champs sont requis');
        return;
      }
    }

    try {
      const res = await fetch('http://152.228.135.183:5000/api/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const data = await res.json();
      if (res.ok) {
        setSuccess('Inscription réussie !');
        setFormData({
          login: '', password: '', tag_id: '', email: '', phone: '',
          nom_contact: '', n_siret: '', contact: '', adresse: '', code_postal: '', ville: ''
        });
        // Redirection vers la page de connexion après 2 secondes
        setTimeout(() => navigate('/'), 2000); 
      } else {
        setError(data.error || "Échec de l'inscription");
      }
    } catch (err) {
      setError('Erreur serveur');
    }
  };

  return (
    <div className="register-container d-flex align-items-center justify-content-center vh-100">
      <div className="register-box p-5 rounded shadow-lg text-center">
        <h2 className="text-glow mb-4">Inscription</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">{success}</div>}
        <form onSubmit={handleSubmit}>
          {Object.keys(formData).map((key) => (
            <div className="mb-3" key={key}>
              <input
                type={key === 'password' ? 'password' : 'text'}
                className="form-control neon-input"
                placeholder={key.replace('_', ' ').toUpperCase()}
                name={key}
                value={formData[key]}
                onChange={handleChange}
                required
              />
            </div>
          ))}
          <button type="submit" className="btn btn-neon w-100">S'inscrire</button>
        </form>
      </div>
    </div>
  );
}

export default Register;
